<template>
    <modal ref="cancelarAdmin" titulo="Cancelar pedido" icon="close" no-aceptar adicional="Rechazar" @adicional="cancelar_pedido">
        <ValidationObserver ref="form2">
            <div class="row mx-0 justify-center">
                <div class="col-10 text-center text-general">
                    Es una lástima que quieras cancelar el pedido, cuéntanos por que tomas esta decisión.
                </div>
            </div>
            <div class="row mx-0 my-3 justify-center">
                <div class="col-10">
                    <label class="text-general pl-3 f-14">Motivo de la cancelación</label>
                    <ValidationProvider v-slot="{ errors }" rules="required" name="motivo">
                        <el-select v-model="model.id_motivo" size="small" class="w-100">
                            <el-option
                            v-for="item in motivos"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <span class="text-danger f-11">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 my-3 justify-center">
                <div class="col-10">
                    <label class="text-general pl-3 f-14">Justificación</label>
                    <ValidationProvider v-slot="{ errors }" rules="required" name="justificacion">
                        <el-input v-model="model.justificacion" type="textarea" show-word-limit maxlength="250" :rows="3" />
                        <span class="text-danger f-11">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import PedidosTienda from '~/services/pedidos/pedidos-tienda'
import Pedidos from '~/services/pedidos/pedidos-admin'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            motivos: [],
            model:{
                id_motivo: '',
                justificacion:'',

            },
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            id_cedis: 'cedis/id_cedis',
        }),
    },
    methods:{
        toggle(){
            this.select_motivos();
            this.$refs.form2.reset();
            this.model = {
                id_motivo: '',
                justificacion:''
            }
            this.$refs.cancelarAdmin.toggle()
        },
        async select_motivos(){
            try {
                let params = {
                    tipo:3
                }
                const {data} = await PedidosTienda.select_motivos(params)
                this.motivos = data.motivos

            } catch (e){
                this.error_catch(e)
            }
        },
        async cancelar_pedido(){
            try {

                if(this.id_pedido === null){
                    this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                    return
                }
                const valid = await this.$refs.form2.validate()
                if(valid){
                    this.model.id_pedido = this.id_pedido
                    this.model.estado = 27
                    const {data} = await Pedidos.rechazar_cancelar_tendero(this.model)

                    this.$emit('accion-cancelar')
                    this.notificacion('Mensaje','Pedido Cancelado','success')
                    this.$refs.cancelarAdmin.toggle()
                }
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
